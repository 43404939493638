body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  
a:hover {
  color: #363636;
}

a {
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;
}