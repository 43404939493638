.Render {
    background-color: none;
    text-align: center;
    overflow: hidden;
}

.zenMode .Render {
    overflow: auto;
    position: relative;
}

.RenderImage {
    width: auto;
    height: auto;
    position: relative;
}

.zenMode .RenderDiagramZone {
    position: relative;
}

.zenMode .RenderImage {
    width: auto;
    position: relative;
}


.zenMode .RenderEditMessage {
    height: 25px;
}

div.react-transform-wrapper {
    -webkit-user-select: all;
    -khtml-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
}

.RenderImageError {
    
}