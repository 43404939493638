.CopyField {
    position: relative;
}

.CopyButton {
    position: absolute !important;
    top: 4px;
    right: 11px;
}

.CopyFieldPre {
    border-width: 2px !important;
    white-space: pre;
    overflow-x: scroll;
    background-color: #f8f8f8 !important;
}

.CopyField.copy-hover .CopyFieldPre {
    border: 2px solid #209cee;
}
