.xColumns {
    display: block;
    width: 100%;
}
.xColumns>* {
    display: inline-block;
    width: 50%;
    position: relative;
}

@media (max-width: 600px) { 
    .xColumns>* {
        display: block;
        width: 100%;
    }
}