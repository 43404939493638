
.App {
    max-width: 1200px;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
}

.code {
    font-family: source-code-pro, Menlo, Monaco, 'Fira Code', Consolas, 'Courier New', monospace !important;
}

.diagramParams {
    margin-bottom: 1rem;
}

#root {
    overflow: hidden;
}

.zenMode .MainPanel {
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
}

.zenMode .NonZen {
    display: none;
}

.controlZone {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
}

.diagramTypeZone {
    flex-grow: 0;
    flex-basis: auto;
    min-width: max-content;
}

.buttonsZone {
    flex-grow: 1;
    flex-basis: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.analyticsPanel {
    line-height: 1px;
    height: 1px;
}