.ExampleDetail {
}

.ExampleDetailCode {
    text-align: left;
    overflow-x: auto;
}

.ui.segment.ExampleDetailCodeSegment {
    background-color: #ffffec;
}