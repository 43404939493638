.Window {
    position: fixed;
    left: 10px;
    right: 10px;
}

.WindowContent.window-centered-content > * {
    justify-content: center;
}

.WindowHeaderButtons {
    text-align: right;
    float: right;
    margin-top: -6px ;
    margin-right: -17.5px;
}

.WindowHeaderComponent {
    text-align: right;
    float: right;
    margin-top: -6px ;
    margin-right: -5px;
}